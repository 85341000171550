import { Flex, Grid, GridItem, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react'

import { Icon, type IconName } from '@miimosa/design-system'

import { Banner } from '@components'

const IconWithText = ({ iconName, text }: { iconName: IconName; text: string }) => {
  return (
    <Flex direction={{ base: 'column', lg: 'row' }} rowGap={3} columnGap={3} alignItems="center">
      <Icon name={iconName} size="sm" />
      <Text size="lg" color="fakeblack" dangerouslySetInnerHTML={{ __html: text }} />
    </Flex>
  )
}

const DotWithText = ({ text }: { text: string }) => {
  return (
    <Flex direction="row" columnGap={2} w="full">
      <Flex w="10px" h="10px" minW="10px" bg="blue.500" borderRadius="50%" mt={2} />
      <Text size="lg" color="fakeblack" dangerouslySetInnerHTML={{ __html: text }} />
    </Flex>
  )
}

const HowItWorks = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  return (
    <Flex w="full" direction="column">
      <Banner text={'Comment ça marche ?'} bgColor={'green.100'} textColor={'green.300'} />
      <Flex direction="column" px={{ base: 8, lg: 32 }} py={16} rowGap={12}>
        <Flex w="full" direction={{ base: 'column', lg: 'row' }} rowGap={6}>
          <Flex w="full" direction="column" rowGap={6}>
            <Flex w="full" direction="row" alignItems="center" columnGap={6}>
              <Icon name="PersonAgri" size="sm" fill="blue.500" />
              <Heading size="sm" color="blue.500">
                Qui est éligible ?
              </Heading>
            </Flex>
            <Flex direction="column" w="full" rowGap={6}>
              <Text color="fakeblack" size="lg">
                Pour être éligible à au moins l’un des cinq appels à projets, les participants doivent répondre aux
                conditions générales suivantes :
              </Text>
              <Flex w="full" direction="column" px={3} rowGap={6}>
                <DotWithText
                  text="Être un <strong>Agriculteur Partenaire</strong> ayant une exploitation agricole qui a une <strong>relation de fourniture</strong> directe ou
        indirecte avec au moins une <strong>entité du Groupement Les Mousquetaires</strong> (Agromousquetaires, unités de production
        d’Agromousquetaires, Intermarché Alimentaires International ou point de vente Intermarché/Netto)"
                />
                <DotWithText text="Disposer de la <strong>capacité juridique adaptée</strong> (structure agricole avec un N° SIRET actif)" />
                <DotWithText text="Être <strong>adhérent</strong> à la <strong>Charte des Bonnes Pratiques d’Élevage</strong> et à la <strong>Charte Agromousquetaires</strong> (lait pâturé et bien-être animal)" />
              </Flex>
            </Flex>
          </Flex>
          <Flex w="full" alignItems="center" justifyContent="center">
            <Flex w="350px" h="350px">
              <Image alt="milk" src={'images/milk.png'} objectFit="cover" />
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" w="full" rowGap={10}>
          <Heading size="sm" color="blue.500">
            Liste des documents communs à fournir
          </Heading>
          <Flex direction={{ base: 'column', lg: 'row' }} columnGap={16} rowGap={10}>
            <IconWithText iconName={'KbisAgri'} text={'KBIS de moins de 3 mois'} />
            <IconWithText iconName={'RibAgri'} text={'RIB de votre exploitation'} />
          </Flex>
        </Flex>
      </Flex>
      <Flex w="full" bg="#F6F6F6" rowGap={{ base: 6, md: 20 }} direction="column" minH="500px" position="relative">
        {!isMobile && (
          <Image
            position="absolute"
            alt="a bottle of milk"
            src={'images/bottle-milk.png'}
            height="325px"
            objectFit="contain"
            top="142px"
            left="89px"
          />
        )}
        <Flex direction="column" w="full" py={{ base: 6, lg: 16 }} zIndex={1} rowGap={20}>
          <Heading size="sm" color="blue.500" px={{ base: 8, lg: 32 }}>
            Le déroulé de l’appel à projets
          </Heading>
          <Flex w="full" pl={{ base: 0, lg: 80 }} pr={{ base: 0, lg: 20 }}>
            <Grid
              templateAreas={{
                base: `"arrow b c"
                "arrow d e"
                "arrow f g"
                "arrow h i"`,
                lg: `"a b c d"
                  "arrow arrow arrow arrow"
                  "f g h i"`,
              }}
              w="full"
              columnGap={5}
              px={6}
            >
              <GridItem w="full" area={{ base: 'b', lg: 'a' }}>
                <Text
                  fontSize="4rem"
                  lineHeight="3.5rem"
                  bgSize="100%"
                  bgClip="text"
                  bgImage="linear-gradient(90deg, rgba(78,129,186,1) 0%, rgba(230,53,44,1) 100%);"
                  style={{ WebkitTextFillColor: 'transparent' }}
                  fontWeight={700}
                >
                  1
                </Text>
              </GridItem>
              <GridItem w="full" area={{ base: 'd', lg: 'b' }}>
                <Text
                  fontSize="4rem"
                  lineHeight="3.5rem"
                  bgSize="100%"
                  bgClip="text"
                  bgImage="linear-gradient(90deg, rgba(230,53,44,1) 0%, rgba(205,131,64,1) 100%);"
                  style={{ WebkitTextFillColor: 'transparent' }}
                  fontWeight={700}
                >
                  2
                </Text>
              </GridItem>
              <GridItem w="full" area={{ base: 'f', lg: 'c' }}>
                <Text
                  fontSize="4rem"
                  lineHeight="3.5rem"
                  bgSize="100%"
                  bgClip="text"
                  bgImage="linear-gradient(90deg, rgba(205,131,64,1) 0%, rgba(192,171,74,1) 100%);"
                  style={{ WebkitTextFillColor: 'transparent' }}
                  fontWeight={700}
                >
                  3
                </Text>
              </GridItem>
              <GridItem w="full" area={{ base: 'h', lg: 'd' }}>
                <Text
                  fontSize="4rem"
                  lineHeight="3.5rem"
                  bgSize="100%"
                  bgClip="text"
                  bgImage="linear-gradient(90deg, rgba(192,171,74,1) 0%, rgba(180,207,83,1) 100%);"
                  style={{ WebkitTextFillColor: 'transparent' }}
                  fontWeight={700}
                >
                  4
                </Text>
              </GridItem>
              <GridItem w="full" area={{ base: 'arrow', lg: 'arrow' }}>
                <Icon
                  name={isMobile ? 'VerticalArrowAgri' : 'ArrowAgri'}
                  boxSize="max-content"
                  minW="50px"
                  h={{ base: '100%', lg: 'auto' }}
                  maxH={{ base: '600px', lg: '100%' }}
                  w="auto"
                />
              </GridItem>
              <GridItem area={{ base: 'c', lg: 'f' }}>
                <Text size="lg" color="fakeblack" maxWidth={{ base: 'auto', lg: '250px' }}>
                  <strong>Dépôt</strong> de votre <strong>candidature</strong>:<br></br>
                  <strong>1ere session</strong> du 17 juillet au 15 septembre 2024<br></br>
                  <strong>2ème session</strong> : du 1er octobre au 15 décembre 2024
                </Text>
              </GridItem>
              <GridItem area={{ base: 'e', lg: 'g' }}>
                <Text size="lg" color="fakeblack" maxWidth={{ base: 'auto', lg: '215px' }}>
                  <strong>Attribution des dotations</strong> du Programme Avenir Lait aux{' '}
                  <strong>Agriculteurs Partenaires</strong> lauréats
                </Text>
              </GridItem>
              <GridItem area={{ base: 'g', lg: 'h' }}>
                <Text size="lg" color="fakeblack" maxWidth={{ base: 'auto', lg: '215px' }}>
                  <strong>Signature</strong> de la <strong>Convention d’Accompagnement</strong> entre Agrimousquetaires
                  et l’Agriculteur Partenaire lauréat
                </Text>
              </GridItem>
              <GridItem area={{ base: 'i', lg: 'i' }}>
                <Text size="lg" color="fakeblack" maxWidth={{ base: 'auto', lg: '215px' }}>
                  À partir d&apos;<strong>octobre 2024</strong> : <strong>1er versement</strong> de la dotation{' '}
                  <i>pour les lauréats dont les factures sont comprises entre le 01/01 et le 15/09/2024</i>
                  <br />À partir de <strong>janvier 2025</strong> : <strong>2ème versement</strong> de la dotation{' '}
                  <i>pour les lauréats dont les factures sont comprises entre le 1er janvier et le 31 décembre 2024</i>
                </Text>
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HowItWorks
