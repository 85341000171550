import { theme as baseTheme, extendTheme } from '@chakra-ui/react'
import localFont from 'next/font/local'

import { Button } from './button'
import { Heading } from './heading'
import { Text } from './text'

const Nunito = localFont({
  src: [
    {
      path: '../../public/fonts/NunitoSansRegular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/NunitoSansItalic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/NunitoSansBold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
})

const Itc = localFont({
  src: [
    {
      path: '../../public/fonts/ITCAvantGardeStd.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/ITCAvantGardeStdItalic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/ITCAvantGardeStdBold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
})

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Heading.sizes = undefined as any

export const theme = extendTheme({
  components: {
    Heading,
    Text,
    Button,
    // Container,
    // Link,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  fonts: {
    heading: `${Itc.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${Nunito.style.fontFamily}, ${baseTheme.fonts.body}`,
  },
  colors: {
    white: '#FFF',
    red: '#E6352C',
    pink: '#FFEFEE',
    blue: {
      100: '#F2F8FF',
      500: '#4E81BA',
    },
    green: {
      100: '#F3FADA',
      300: '#B4CF53',
      900: '#183F30',
    },
    fakeblack: '#241B00',
    gray: { 900: '#4A4A4A' },
    grey: { 900: '#4A4A4A' },
    checkbox: { 500: '#4E81BA' },
  },
})
