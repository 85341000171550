import { RequestCookies } from '@edge-runtime/cookies'
import { type NextRequest, NextResponse } from 'next/server'

//Need to be called with full path because index in api export function used in edge runtime....
import { openV2SessionWithoutAxios } from '@miimosa/api/v3/v2sessionRuntimed'
import {
  MIIMOSA_FLASH,
  MIIMOSA_SESSION,
  MIIMOSA_SIGNATURE,
  getRootDomainFromHost,
  MIIMOSA_SESSION_V3,
} from '@miimosa/common'
import splitV2Signature from '@miimosa/common/helpers/users'
import { V2Session } from '@miimosa/protocol'

const secure = process.env.NODE_ENV === 'production'
const sameSite = 'strict'

function bypassMiddleware(req: NextRequest): boolean {
  const { pathname } = req.nextUrl // get pathname of request (e.g. /blog-slug)
  return (
    pathname.includes('.') ||
    pathname.startsWith('/api') ||
    pathname.startsWith('/images') ||
    pathname.includes('/nextapi')
  )
}

const checkAuthRequired = (req: NextRequest, res: NextResponse) => {
  const protectedRoutes = ['dashboard', 'informations-reglementaires-psfp', 'notifications']
  let returnedValue: undefined | NextResponse = undefined
  const { pathname } = req.nextUrl
  protectedRoutes.map((p) => {
    if (pathname.startsWith(`/${p}`)) {
      const cookies = new RequestCookies(req.headers)
      if (
        // if cookie not already present
        (cookies.get(MIIMOSA_SESSION_V3)?.value == undefined &&
          // if cookie not generated from this request
          res.cookies.get('_miimosa_session_v3')?.value == undefined) ||
        res.cookies.get('_miimosa_session_v3')?.value == ''
      ) {
        returnedValue = NextResponse.redirect(new URL(`/authenticate?return_url=/${p}`, req.url))
      }
    }
  })
  return returnedValue
}

function cookieDomain(headers: Headers) {
  const host = headers.get('host')
  const root = getRootDomainFromHost(host ?? undefined)
  return '.' + root
}

// We add the cookie signature so we can compare it to the public user returned by the API
// See the useV2User hook
async function handleV2Cookie(headers: Headers, res: NextResponse, session: V2Session.OpenResponse | undefined) {
  const cookies = new RequestCookies(headers)
  let cookie = cookies.get(MIIMOSA_SESSION)?.value
  if (!cookie) {
    res.cookies.delete(MIIMOSA_FLASH)
    res.cookies.delete(MIIMOSA_SESSION)
    res.cookies.delete(MIIMOSA_SIGNATURE)
    res.cookies.delete(MIIMOSA_SESSION_V3)
    return
  }

  const domain = cookieDomain(headers)
  // Can't use axios here until because of edge runtime :(
  // This code should work on both node and edge

  if (!session) return
  if (session.flashes) {
    res.cookies.set(MIIMOSA_FLASH, JSON.stringify(session.flashes), { httpOnly: false, secure, sameSite })
  }
  if (session.new_cookie) {
    res.cookies.set(MIIMOSA_SESSION, session.new_cookie, { httpOnly: true, secure, domain })
    cookie = session.new_cookie
  }

  if (session.user_id != 0) {
    const jwt_session = headers.get('jwt_v3')
    if (jwt_session) {
      // set jwt in browser cookie
      res.cookies.set(MIIMOSA_SESSION_V3, jwt_session, { httpOnly: false, secure, sameSite })
    }
    const sign = splitV2Signature(cookie)
    res.cookies.set(MIIMOSA_SIGNATURE, sign ?? '', {
      httpOnly: false,
      secure,
      sameSite,
    })
    return res
  } else {
    res.cookies.delete(MIIMOSA_SESSION_V3)
    return
  }
}

const handleRequestHeaders = async (headers: Headers) => {
  const cookies = new RequestCookies(headers)
  let session = undefined
  const cookie = cookies.get(MIIMOSA_SESSION)?.value
  const jwt = cookies.get(MIIMOSA_SESSION_V3)?.value
  let JWTdata: V2Session.JWT = {
    access_token: '',
    expires_at: '',
    refresh_token: '',
  }
  if (jwt) {
    JWTdata = JSON.parse(jwt)
  }
  if (cookie) {
    // Can't use axios here until because of edge runtime :(
    // This code should work on both node and edge
    session = await openV2SessionWithoutAxios(cookie, jwt ? JWTdata : undefined)
    if (session) {
      // if there is no jwt yet, save a new one
      // `;${MIIMOSA_SESSION_V3}=${JSON.stringify(session.jwt)};HttpOnly=false;Secure=${secure};SameSite=${sameSite}`
      if (!jwt && session.jwt) {
        headers.set('jwt_v3', `${JSON.stringify(session.jwt)}`)
      }
      // Login as method as been used
      if (jwt && session.jwt) {
        headers.set('jwt_v3', `${JSON.stringify(session.jwt)}`)
      }
    }
  }
  // In every case we return headers, so if jwt not created, we still return headers from classic request
  return { headers, session }
}

export default async function middleware(req: NextRequest) {
  // TODO: Move into common package

  if (bypassMiddleware(req)) return
  // get classic headers
  const requestHeaders = new Headers(req.headers)
  // we need to do this do create new headers to send in "response request"
  const { headers: newRequestHeaders, session } = await handleRequestHeaders(requestHeaders)
  const res = NextResponse.next({
    request: {
      headers: newRequestHeaders,
    },
  })

  await handleV2Cookie(newRequestHeaders, res, session)
  const shouldRedirect = checkAuthRequired(req, res)
  if (shouldRedirect) {
    return shouldRedirect
  }
  return res
}
