import {
  Flex,
  Input,
  SimpleGrid,
  Heading,
  CheckboxGroup,
  Checkbox,
  Text,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Link,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm, SubmitHandler, FormProvider, useFormContext, FieldError, Controller } from 'react-hook-form'

import { Banner } from '@components'

type Inputs = {
  exploitation_name: string
  siret: string
  productor_number: string
  email: string
  zipcode: number
  city: string
  lastname: string
  firstname: string
  mobile: string
  aap: string[]
}

const ids = [
  '1317',
  '1613',
  '2965',
  '3008',
  '3103',
  '3108',
  '3109',
  '3124',
  '3133',
  '3143',
  '3161',
  '3242',
  '3298',
  '3305',
  '3313',
  '3345',
  '3346',
  '3351',
  '3354',
  '3359',
  '3369',
  '3388',
  '3394',
  '3406',
  '3418',
  '3419',
  '3428',
  '3435',
  '3464',
  '3474',
  '3476',
  '3488',
  '3490',
  '3494',
  '3498',
  '3500',
  '3502',
  '3507',
  '3510',
  '3526',
  '3529',
  '3530',
  '3540',
  '3545',
  '3549',
  '3563',
  '3568',
  '3569',
  '3573',
  '3575',
  '3582',
  '3593',
  '3613',
  '3616',
  '3617',
  '3620',
  '3625',
  '3626',
  '3630',
  '3631',
  '3633',
  '3634',
  '3638',
  '3641',
  '3645',
  '3646',
  '3655',
  '3657',
  '3659',
  '3664',
  '3665',
  '3668',
  '3670',
  '3675',
  '3678',
  '3685',
  '3695',
  '3697',
  '3703',
  '3707',
  '3708',
  '3714',
  '3715',
  '3716',
  '3718',
  '3719',
  '3721',
  '3722',
  '3723',
  '3725',
  '3730',
  '3734',
  '3736',
  '3739',
  '3742',
  '3743',
  '3744',
  '3753',
  '3755',
  '3760',
  '3761',
  '3763',
  '3767',
  '3771',
  '3773',
  '3776',
  '3777',
  '3782',
  '3791',
  '3792',
  '3797',
  '3798',
  '3799',
  '3801',
  '3802',
  '3804',
  '3810',
  '3815',
  '3816',
  '3820',
  '3824',
  '3827',
  '3830',
  '3831',
  '3832',
  '3833',
  '3835',
  '3837',
  '3842',
  '3843',
  '3850',
  '3854',
  '3855',
  '3859',
  '3866',
  '3868',
  '3869',
  '3874',
  '3877',
  '3878',
  '3883',
  '3885',
  '3888',
  '3890',
  '3893',
  '3899',
  '3901',
  '3906',
  '3907',
  '3908',
  '3909',
  '3910',
  '3911',
  '3913',
  '3915',
  '3916',
  '3917',
  '3918',
  '3919',
  '3921',
  '3925',
  '3927',
  '3930',
  '3934',
  '3935',
  '3936',
  '3938',
  '3940',
  '3941',
  '3943',
  '3946',
  '3947',
  '3948',
  '3949',
  '3950',
  '3951',
  '3952',
  '3955',
  '3956',
  '3960',
  '3961',
  '3964',
  '3965',
  '3966',
  '3968',
  '3969',
  '3971',
  '3972',
  '3974',
  '3975',
  '3976',
  '3977',
  '3980',
  '3981',
  '3982',
  '3983',
  '3985',
  '3987',
  '3988',
  '3989',
  '3990',
  '3991',
  '3992',
  '3994',
  '3995',
  '3996',
  '3998',
  '4001',
  '4003',
  '4005',
  '4006',
  '4007',
  '4008',
  '4009',
  '4010',
  '4011',
  '4014',
  '4017',
  '4018',
  '4019',
  '4020',
  '4022',
  '4024',
  '4025',
  '4027',
  '4028',
  '4030',
  '4031',
  '4032',
  '4033',
  '4034',
  '4035',
  '4039',
  '4040',
  '4041',
  '4042',
  '4044',
  '4045',
  '4046',
  '4047',
  '4048',
  '4049',
  '4051',
  '4052',
  '4054',
  '4055',
  '4056',
  '4058',
  '4059',
  '4060',
  '4061',
  '4062',
  '4063',
  '4064',
  '4065',
  '4066',
  '4067',
  '4068',
  '4069',
  '4070',
  '4072',
  '4073',
  '4074',
  '4075',
  '4076',
  '4078',
  '4079',
  '4081',
  '4082',
  '4083',
  '4084',
  '4085',
  '4086',
  '4087',
  '4088',
  '4089',
  '4090',
  '4091',
  '4093',
  '4094',
  '4095',
  '4097',
  '4098',
  '4099',
  '4100',
  '4102',
  '4103',
  '4104',
  '4105',
  '4106',
  '4107',
  '4108',
  '4109',
  '4110',
  '4111',
  '4112',
  '4113',
  '4114',
  '4115',
  '4116',
  '4117',
  '4118',
  '4119',
  '4120',
  '4121',
  '4122',
  '9151',
  '9154',
  '9156',
  '9157',
  '9159',
  '9170',
  '9174',
  '9178',
  '9179',
  '9186',
  '9187',
  '9188',
  '9189',
  '9202',
  '9203',
  '9204',
  '9205',
  '9206',
  '9208',
  '9209',
  '9210',
  '9214',
  '9215',
  '9216',
  '9219',
  '9220',
  '9241',
  '9242',
  '9243',
  '9244',
  '9245',
  '9246',
  '9247',
  '9249',
  '9250',
  '9251',
  '9252',
  '9261',
  '9263',
  '9267',
  '9281',
  '9283',
  '9284',
  '9287',
  '9288',
  '9289',
  '9290',
  '9291',
  '9295',
  '9301',
  '9302',
  '9303',
  '9304',
  '9305',
  '9307',
  '9308',
  '9309',
  '9311',
  '9312',
  '9313',
  '9314',
  '9315',
  '9316',
  '9317',
  '9401',
  '9402',
  '9403',
]

const FormField = ({
  name,
  type,
  isInvalid,
  displayName,
  errors,
}: {
  name: keyof Inputs
  type: 'text' | 'number' | 'tel' | 'email'
  isInvalid: boolean
  displayName: string
  errors?: FieldError
}) => {
  const { register } = useFormContext()

  return (
    <FormControl isInvalid={isInvalid}>
      <Input
        px={2}
        type={type}
        border="none"
        bg="white"
        placeholder={displayName}
        {...(type == 'number'
          ? { ...register(name, { required: true, max: 99999999999999, min: 10 }) }
          : { ...register(name, { required: true, maxLength: 100, minLength: 1 }) })}
      />
      <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'maxLength' && (
        <Text color="red" size="sm">
          Ce champ ne doit pas faire plus de 100 caractères.
        </Text>
      )}
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'minLength' && (
        <Text color="red" size="sm">
          Ce champ doit au minimum faire 1 caractère.
        </Text>
      )}
      {type == 'number' && errors?.type == 'min' && (
        <Text color="red" size="sm">
          Ce champ ne doit pas avoir de valeur inférieure ou égale à 0.
        </Text>
      )}
      {type == 'number' && errors?.type == 'max' && (
        <Text color="red" size="sm">
          Ce champ ne doit pas avoir de valeur supérieur à 99999999999999.
        </Text>
      )}
    </FormControl>
  )
}

const Form = () => {
  const methods = useForm<Inputs>()

  const [spinner, setSpinner] = useState<boolean>(false)
  const [submittedText, setSubmittedText] = useState({ text: '', color: '' })

  const [errorProdNumber, setErrorProdNumber] = useState(false)

  const [isChecked, setIsChecked] = useState(false)
  const selectedAap = methods.watch('aap')

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSpinner(true)

    if (ids.includes(data.productor_number)) {
      const response = await fetch('/nextapi/leads', {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          mobile: data.mobile,
          exploitation_name: data.exploitation_name,
          zipcode: data.zipcode,
          city: data.city,
          siret: data.siret,
          productor_number: data.productor_number,
          aap: data.aap,
        }),
      })
      if (response.status === 200) {
        setSpinner(false)
        setSubmittedText({
          text: 'Merci pour votre demande de contact, vous recevrez un mail automatique pour compléter votre candidature',
          color: 'fakeblack',
        })
      } else {
        setSpinner(false)
        setSubmittedText({
          text: 'Un problème est survenu, veuillez <u><a href="mailto:agrimousquetaires@miimosa.com">nous contacter</a></u>.',
          color: 'red',
        })
      }
    } else {
      setSpinner(false)
      setErrorProdNumber(true)
      setSubmittedText({
        text: 'Un problème est survenu, veuillez <u><a href="mailto:agrimousquetaires@miimosa.com">nous contacter</a></u>.',
        color: 'red',
      })
    }
  }

  return (
    <Flex direction="column" bg={'blue.100'} w="full" alignItems="center" pb={20} id="form">
      <Banner text={'FORMULAIRE DE CONTACT'} bgColor={'blue.100'} textColor={'blue.500'} />
      <FormProvider {...methods}>
        <Flex
          direction="column"
          maxWidth={'1200px'}
          alignItems="center"
          w="full"
          rowGap={12}
          px={{ base: 3, lg: 0 }}
          as="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Flex direction="column" rowGap={10} w="full">
            <SimpleGrid columns={{ base: 1, lg: 2 }} columnGap={6} rowGap={10}>
              <FormField
                name={'exploitation_name'}
                type={'text'}
                displayName={'Nom de l’exploitation'}
                isInvalid={methods.formState.errors.exploitation_name?.type == 'required'}
                errors={methods.formState.errors.exploitation_name}
              />
              <FormField
                name={'siret'}
                type={'number'}
                displayName={'Numéro de SIRET (14 chiffres)'}
                isInvalid={methods.formState.errors.siret?.type == 'required'}
                errors={methods.formState.errors.siret}
              />
              <Flex direction="column" rowGap={2}>
                <FormField
                  name={'productor_number'}
                  type={'text'}
                  displayName={'Numéro de producteur Laiterie'}
                  isInvalid={methods.formState.errors.productor_number?.type == 'required'}
                  errors={methods.formState.errors.productor_number}
                />
                {errorProdNumber && (
                  <Text color="red" fontSize="0.875rem">
                    Ce numéro ne semble pas valide. Si vous rencontrez des difficultés{' '}
                    <u>
                      <a href="mailto:agrimousquetaires@miimosa.com">contactez-nous</a>
                    </u>
                    .
                  </Text>
                )}
              </Flex>
              <FormField
                name={'email'}
                type={'text'}
                displayName={'Adresse e-mail de l’exploitation'}
                isInvalid={methods.formState.errors.email?.type == 'required'}
                errors={methods.formState.errors.email}
              />
              <FormField
                name={'zipcode'}
                type={'number'}
                displayName={'Code postal'}
                isInvalid={methods.formState.errors.zipcode?.type == 'required'}
                errors={methods.formState.errors.zipcode}
              />
              <FormField
                name={'city'}
                type={'text'}
                displayName={'Commune'}
                isInvalid={methods.formState.errors.city?.type == 'required'}
                errors={methods.formState.errors.city}
              />
              <Flex direction="column" w="full" rowGap={2}>
                <FormField
                  name={'lastname'}
                  type={'text'}
                  displayName={'Nom du demandeur'}
                  isInvalid={methods.formState.errors.lastname?.type == 'required'}
                  errors={methods.formState.errors.lastname}
                />
                {selectedAap && selectedAap.includes('vacation') && (
                  <Text size="sm">dans la limite de 4 demandeurs par exploitation</Text>
                )}
              </Flex>
              <FormField
                name={'firstname'}
                type={'text'}
                displayName={'Prénom du demandeur'}
                isInvalid={methods.formState.errors.firstname?.type == 'required'}
                errors={methods.formState.errors.firstname}
              />
              <FormField
                name={'mobile'}
                type={'tel'}
                displayName={'Numéro de téléphone du demandeur'}
                isInvalid={methods.formState.errors.mobile?.type == 'required'}
                errors={methods.formState.errors.mobile}
              />
            </SimpleGrid>
            <Flex direction="column" rowGap={6}>
              <FormControl>
                <FormLabel mb={4}>
                  <Heading color="blue.500" size="xs" textAlign={{ base: 'center', lg: 'start' }}>
                    Vous êtes intéressé par l’un/les appel(s) à projet suivants :
                  </Heading>
                </FormLabel>
                <Controller
                  name="aap"
                  control={methods.control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <CheckboxGroup colorScheme="checkbox" onChange={onChange} value={value}>
                      <Flex direction="column" rowGap={3}>
                        <Checkbox value="vacation" name={name}>
                          <Text size="xl" color="fakeblack">
                            Aide au remplacement pour congés vacances
                          </Text>
                        </Checkbox>
                        <Checkbox name={name} value="install">
                          <Text size="xl" color="fakeblack">
                            Pack à l’installation des Jeunes Agriculteurs
                          </Text>
                        </Checkbox>
                        <Checkbox name={name} value="energy">
                          <Text size="xl" color="fakeblack">
                            Aide aux équipements à réduction énergie
                          </Text>
                        </Checkbox>
                        <Checkbox name={name} value="diagnostic">
                          <Text size="xl" color="fakeblack">
                            Diagnostic Autoconsommation
                          </Text>
                        </Checkbox>
                        <Checkbox name={name} value="working">
                          <Text size="xl" color="fakeblack">
                            Amélioration des conditions de travail / sécurité
                          </Text>
                        </Checkbox>
                      </Flex>
                    </CheckboxGroup>
                  )}
                />
                {methods.formState.errors.aap && <Text color="red">Ce champ est requis</Text>}
              </FormControl>
              <Flex>
                <Text size="md" fontStyle="italic">
                  Vous recevrez un mail automatique correspondant à chaque appel à projet pour lequel vous avez
                  manifesté votre intérêt
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column" rowGap={6}>
            <Flex direction="row" columnGap={3}>
              <Checkbox
                name="cgu"
                colorScheme="checkbox"
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <Link
                isExternal
                href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/partners/CGU_AGRIMOUSQUETAIRES.pdf"
                onClick={() => setIsChecked(!isChecked)}
              >
                <Text size="lg" color="fakeblack">
                  J’accepte les CGU
                </Text>
              </Link>
            </Flex>

            <Text size="sm" color="gray.900" whiteSpace="pre-wrap">
              {
                "En renseignant vos données, vous acceptez qu’AGRIMOUSQUETAIRES traite vos données personnelles en qualité de responsable de traitement à des fins de communication et d’échanges d’informations relatifs aux appels à projets. Vous pouvez à tout moment retirer votre consentement et vous disposez d’un droit d'accès, de rectification, d'effacement de vos données, d'un droit à la limitation du traitement, d'un droit à la portabilité de vos données et du droit de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès. Vous pouvez exercer ces droits en contactant le délégué à la protection des données par courrier à l'adresse suivante : agrimousquetaires@miimosa.com\nEn cas de réclamation, vous disposez du droit de saisir la CNIL.\nPour en savoir plus sur vos droits, vous pouvez consulter notre Politique Données Personnelles et vous inscrire gratuitement sur la liste nationale d’opposition au démarchage téléphonique sur le site www.bloctel.gouv.fr"
              }
            </Text>
          </Flex>
          {spinner ? (
            <Flex w="full" justifyContent="center">
              <Spinner />
            </Flex>
          ) : (
            <Flex direction="column" w="full" alignItems="center" rowGap={3}>
              <Text color={submittedText.color} dangerouslySetInnerHTML={{ __html: submittedText.text }} />
              <Button
                variant="primary"
                size="md"
                w="fit-content"
                type="submit"
                isDisabled={!isChecked || selectedAap?.length == 0}
              >
                Envoyer ma demande de contact
              </Button>
            </Flex>
          )}
        </Flex>
      </FormProvider>
    </Flex>
  )
}

export default Form
