import { BoxProps, Flex, Text } from '@chakra-ui/react'

import { Icon, type IconName } from '@miimosa/design-system'

const GreenBloc = ({
  iconName,
  text,
  subTexts,
  bolded = false,
  ...props
}: {
  iconName: IconName
  text: string
  subTexts: string[]
  bolded?: boolean
} & BoxProps) => {
  return (
    <Flex w="full" direction="column" bg="green.300" p={6} rowGap={bolded ? 1 : 5} {...props}>
      <Flex w="full" direction="column" rowGap={4}>
        <Icon name={iconName} size="2xs" fill="white" />
        <Text size="xl" color="white" fontWeight={bolded ? '700' : '400'}>
          {text}
        </Text>
      </Flex>
      <Flex direction="column" w="full">
        {bolded ? (
          <Text size="lg" color="white" dangerouslySetInnerHTML={{ __html: subTexts[0] }} />
        ) : (
          subTexts.map((text, i) => (
            <Flex direction="row" w="full" key={i} alignItems="center" columnGap={3}>
              <Flex w="5px" h="5px" bg="white" borderRadius="50%" />
              <Text size="lg" color="white" dangerouslySetInnerHTML={{ __html: text }} />
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  )
}

export default GreenBloc
