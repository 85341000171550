import { Flex, Button, Text, Link, Image } from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const Footer = () => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} bg="green.900" py={10} alignItems="center" rowGap={6}>
      <Flex w="full" direction="column" rowGap={6} alignItems="center">
        <Link isExternal href="https://miimosa.com">
          <Icon name={'Logo2'} color="white" size="2xl" h="auto" />
        </Link>
        <Flex w="full" direction="row" alignItems="center" justifyContent="center" columnGap={3}>
          <Link isExternal href="https://www.instagram.com/MiiMOSA_fr/">
            <Icon name="Instagram" color="white" size="4xs" h="auto" />
          </Link>
          <Link isExternal href="https://www.facebook.com/MiiMOSAfr">
            <Icon name="Facebook" color="white" size="4xs" />
          </Link>
          <Link isExternal href="https://www.linkedin.com/company/miimosa/">
            <Icon name="LinkedIn" color="white" size="4xs" h="auto" />
          </Link>
        </Flex>
        <Link isExternal href="https://blog.miimosa.com/">
          <Button variant="primary" size="md" w="fit-content">
            Blog MiiMOSA
          </Button>
        </Link>
      </Flex>
      <Flex w="full" direction="column" rowGap={6} alignItems="center">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          w="full"
          columnGap={3}
          rowGap={6}
          justifyContent="center"
          alignItems="center"
        >
          <Icon name={'LogoAgri'} color="white" size="md" h="auto" />
          <Link isExternal href="https://www.mousquetaires.com/">
            {/* <Icon name="MousquetairesAgri" color="white" size="md" h="auto" /> */}
            <Image src="images/groupement-mousquetaires.png" alt="" width="80px" />
          </Link>
        </Flex>
        <Flex w="full" direction="row" alignItems="flex-start" columnGap={3} justifyContent="center">
          <Link isExternal href="https://www.linkedin.com/company/les-mousquetaires/" display="flex">
            <Icon name="LinkedIn" color="white" size="4xs" h="auto" />
          </Link>
          <Link
            isExternal
            href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/partners/CGU_AGRIMOUSQUETAIRES.pdf"
            color={'white'}
          >
            <Text color="white" size="xl">
              CGU
            </Text>
          </Link>
          <Link
            color="white"
            isExternal
            href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/partners/mentions-legales-AGRIMOUSQUETAIRES.pdf"
          >
            <Text color="white" size="xl">
              Mentions Légales
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer
